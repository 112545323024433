.Admission-main{
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content:space-evenly;
   gap: 100px;
}
.Add_Animation{
margin-top: 200px;
position:-1;
}

.Admission_main_content{
height: 400px;
width: 600px;
font-family: "Roboto", sans-serif;

}

.Admission_main_content h1{
    font-size: 50px;
  font-weight: 500;
  letter-spacing: 5px;
  color:#103D2E;
}
.Admission_main_content p{
    font-size: 18px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

@media (max-width: 490px) {
  .Admission-main{
    height: 150vh;
    width: auto;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content:flex-start;
}
.Add_Animation{
margin-top: 0px;
position:-1;
}

.Admission_main_content{
  padding: 10px 15px ;
height: 100vh;
width: 100vw;
font-family: "Roboto", sans-serif;
text-align: justify;
}

.Admission_main_content h1{
    font-size: 40px;
  font-weight: 500;
  letter-spacing: 2px;
  color:#103D2E;
  
}
.Admission_main_content p{
    font-size: 18px;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}
  
}
