body {
  margin: 0;
  padding: 0;
  height: 100vh;
}

.cmd {
  padding: 40px;
  background-color: #f8f5f5e2;
}

.grid_container {
  margin: 100px auto;
  padding: 50px;
  background-color: #fff5f5;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

}
.grid_container h1{
  font-family: "Roboto", sans-serif;
  font-size: 40px;
  font-weight:bold ;
  color:#103D2E;
}

.grid_container h2{
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight:400 ;
  color:#103D2E;
}
.grid_content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 50px;
}

.notification {
  display: flex;
  flex-direction: column;
  isolation: isolate;
  position: relative;
  width: 100%;
  height: auto;
  background: #29292c;
  border-radius: 1rem;
  overflow: hidden;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 16px;
  --gradient: linear-gradient(to bottom, #cbeacc, #103e13, #1d9633);
  --color: #ffffff;
  padding: 1rem;

}

.notification:before {
  position: absolute;
  content: "";
  inset: 0.0625rem;
  border-radius: 0.9375rem;
  background: #152a23;
  z-index: 2;
}

.notification:after {
  position: absolute;
  content: "";
  width: 0.25rem;
  inset: 0.65rem auto 0.65rem 0.5rem;
  border-radius: 0.125rem;
  background: var(--gradient);
  transition: transform 300ms ease;
  z-index: 4;
}

.notification:hover:after {
  transform: translateX(0.15rem);
}

.notititle {
  color: var(--color);
  padding: 0.65rem 0.25rem 0.4rem 1.25rem;
  font-weight: 500;
  font-size: 1.1rem;
  transition: transform 300ms ease;
  z-index: 5;
}

.notification:hover .notititle {
  transform: translateX(0.15rem);
}

.notibody {
  color: #99999d;
  padding: 0 1.25rem;
  transition: transform 300ms ease;
  z-index: 5;
}

.notification:hover .notibody {
  transform: translateX(0.25rem);
}

.notiglow,
.notiborderglow {
  position: absolute;
  width: 20rem;
  height: 20rem;
  transform: translate(-50%, -50%);
  background: radial-gradient(circle closest-side at center, rgb(255, 255, 255), transparent);
  opacity: 0;
  transition: opacity 300ms ease;
}

.notiglow {
  z-index: 3;
}

.notiborderglow {
  z-index: 1;
}

.notification:hover .notiglow {
  opacity: 0.1;
}

.notification:hover .notiborderglow {
  opacity: 0.1;
}

.note {
  color: var(--color);
  position: fixed;
  top: 80%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 0.9rem;
  width: 75%;
}


@media (max-width: 490px) {
  
.cmd {
  padding: 10px;
}

.grid_container {
  margin: 20px auto;
  padding: 10px;
  background-color: #fff5f5;
  display: flex;
  flex-direction: column;

}
.grid_container h1{
  font-family: "Roboto", sans-serif;
  font-size: 27px;
  font-weight:bold ;
  color:#103D2E;
}

.grid_container h2{
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  font-weight:400 ;
  color:#103D2E;
}
.grid_content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

  }