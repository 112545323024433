/* From Uiverse.io by Yaya12085 */ 
.card1 {
    box-sizing: border-box ;
    display: flex;
    max-width: 400px;
    background-color: rgba(255, 255, 255, 1);
    transition: all .15s cubic-bezier(0.4, 0, 0.2, 1);
    border: 2px solid #ccc;
    border-radius: 8px;
    height: 380px;

  }
  
  .card1:hover {
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.081);
  }
  
  .date-time-container {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    padding: 0.5rem;
  }
  
  .date-time {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 1rem;
    gap: 3rem;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    color: rgba(17, 24, 39, 1);
    font-family: "Roboto", sans-serif;

  }
  
  .separator {
    width: 1px;
    flex: 1 1 0%;
    background-color: rgba(17, 24, 39, 0.1);
  }
  
  .content {
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .infos {
    border-left: 1px solid rgba(17, 24, 39, 1);
    padding: 1rem;
    font-family: "Roboto", sans-serif;

  }
  
  .title {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 18.72px;
    color: rgba(17, 24, 39, 1);
  }
  
  .description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
    line-clamp: 5;
    margin-top: 0.9rem;
    font-size: 1rem;
    line-height: 1.55rem;
    color: rgba(55, 65, 81, 1);
  }
  
  .action {
    display: block;
    background-color: rgba(253, 224, 71, 1);
    padding: 0.75rem 1.25rem;
    text-align: center;
    font-size: 1.1rem;
    line-height: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    color: rgba(17, 24, 39, 1);
    transition: all .15s cubic-bezier(0.4, 0, 0.2, 1);
    font-family: "Roboto", sans-serif;

  }
  
  .action:hover {
    background-color: rgba(250, 204, 21, 1);
  }


  @media (max-width: 480px) {
    *{
      
      .card1 {
        display: flex;
        max-width: 320px;
        height: 330px;
    
      }
      .description {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 8;
        line-clamp: 5;
        
      }
  }
}