.navbar {
  height: 13vh;
  width: 100%;
  display: flex;
  justify-content:space-evenly; 
  align-items: center;
  padding: 10px 0px;
  background-color: #fff;
  color: #050404;
  top: 0;
  position: fixed;
  z-index: 1;
  box-shadow: 5px 10px 10px rgba(35, 74, 42, 0.1);
  gap: 0px;
}

.logo-container {
  margin-right: 10px;
}

.logo {
  margin-top:50px ;
  height: 450px;

}

.nav-links {
  display: flex;
  justify-content: flex-end;
}

.link {
  color: #103D2E;
  text-decoration: none;
  margin: 0 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 20px;
  position: relative;
  overflow: hidden;
}

.link::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px; 
  background-color: #103D2E;
  bottom: 0;
  left: 0;
  transition: width 0.5s ease; 
}

.link:hover::before {
  width: 100%;
  transition-delay: 0.1s;
}
.nav-toggle {
  display: none;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: rgb(184, 33, 33);
  padding: 0px 0px;
}

@media (max-width: 1250px) {
  .navbar {
    gap: 20px;

  }
}

@media (max-width: 1080px) {
  .navbar {
    gap: 20px;

  }

  .logo-container {
    margin-right: -40px;
  }
  .logo {
    height: 250px;
  
  }
}
@media (max-width: 780px) {
  .navbar {
    height: 13vh;
    width: 100vw;
    display: flex;
    justify-content:space-between; 
    align-items:center;
    padding: 0 0;
    background-color: #fff;
    color: #050404;
    top: 0;
    z-index: 1;
    box-shadow: none ;
    gap: 10px;
    position:relative;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    align-items:start;
    position: fixed;
    top: 100px; 
    right: 0;
    background-color:#103D2E;
    z-index: 1;
    border-radius: 0px;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    justify-content:flex-start;
    gap:0px;

  }

  .nav-links.open {
    display: flex;
  }

  
  
.link {
  color: #ffffff;
  text-decoration: none;
  margin: 0 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 30px;
  position:relative;
  overflow: hidden;
  letter-spacing: 2px;
  margin-top: 50px;

}

.link::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px; 
  background-color: #103D2E;
  bottom: 0;
  left: 0;
  transition: width 0.5s ease; 
}

  .nav-toggle {
    cursor: pointer;
    flex-direction: column;
    margin-right: 20px;
  }

  .logo {
    margin-top:20px ;
    height: 250px;
    width: auto;
  }
  .logo-container {
    margin-right: 0px;
  }
  

  .nav-toggle {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  
  button{
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    }
    
}



@media (max-width: 490px ) {
  .navbar {
    height: 13vh;
    width: 100vw;
    display: flex;
    justify-content:space-between; 
    align-items:center;
    padding: 0 0;
    background-color: #fff;
    color: #050404;
    top: 0;
    z-index: 1;
    box-shadow: none ;
    gap: 10px;
    position:relative;
  }

  .nav-links {
    display: none;
    flex-direction: column;
    align-items:start;
    position: fixed;
    top: 100px; 
    right: 0;
    background-color:#103D2E;
    z-index: 1;
    border-radius: 0px;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    justify-content:flex-start;
    gap:0px;

  }

  .nav-links.open {
    display: flex;
  }

  
  
.link {
  color: #ffffff;
  text-decoration: none;
  margin: 0 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 30px;
  position:relative;
  overflow: hidden;
  letter-spacing: 2px;
  margin-top: 50px;

}

.link::before {
  content: '';
  position: absolute;
  width: 0;
  height: 2px; 
  background-color: #103D2E;
  bottom: 0;
  left: 0;
  transition: width 0.5s ease; 
}

  .nav-toggle {
    cursor: pointer;
    flex-direction: column;
    margin-right: 20px;
  }

  .logo {
    margin-top:20px ;
    height: 250px;
    width: auto;
  }
  .logo-container {
    margin-right: 0px;
  }
  

  .nav-toggle {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  
  button{
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    }
    
}