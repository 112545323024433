@font-face {
    font-family: Gotham-Light;
    src: url(../Assets/Images/Gotham-Font/Gotham-Light.otf);
}

.Footer{
height: 800px;
width: auto;
background-color: #103D2E;

}
.ContentSection{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 20px;
    height: 340px;
    color: yellow;
font-family: Gotham-Light;
line-height: 200%;

}
.Footer h2{
    margin-bottom: 30px;
}

.BottomFooter{
text-align: center;
color: yellow;
font-family: Gotham-Light;
}

.Content h4:hover {
    color: #fff; 
    cursor: pointer;
}
.ContentSection hr{
   display: none;
}

@media (max-width: 480px) {
    .Footer {
        height: 100%;
        width: auto;
        overflow: hidden;
    }
   
    .ContentSection {
        margin-top: 100px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        height: 550px;
        color: yellow;
        font-family: Arial, sans-serif;
        line-height: 130%;
        text-align: left;
        align-items: flex-start;
        padding: 20px 30px;
    }
    .Content{
        margin-bottom: 30px;
    }
    .ContentSection hr{
         width: 60px; 
         border: 0;
         border-bottom: 1px solid #f7f8f8;
         margin-left: 2px;
         margin-bottom: 10px ;
    }
    .Footer h2 {
        margin-bottom: 20px;
        font-weight: lighter;
        letter-spacing: 2px;
        font-size: 17px;
    }
    .Footer h4{
    font-weight: lighter;

    }
    .BottomFooter {
        padding-top: 100px;
        text-align: center;
        color: yellow;
        font-family: "Roboto", sans-serif;
        font-weight: lighter;
        letter-spacing: 1px;
    }
    .ContentSection h4:hover {
        color: #fff; 
        cursor: pointer;
    }
    .Footer_Animation {
        display: none;
    }
    .Footer_Logo {
        margin-top: 10px;
        height: 100px;
        width: auto;
        z-index: 1;
        padding-left: 60px;
    }
    .Footer_Logo img {
        height: 200px;
    }
}